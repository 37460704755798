import http from "../http.js";

const api = {
  //星球数据
  rjtRegion: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtRegion/queryByPage`,
      data: params,
    });
  },
  //领导小组
  meshUser: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtMeshUser/queryByPage`,
      data: params,
    });
  },
  meshUserTree: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtMeshUser/meshUserTree`,
      data: params,
    });
  },
};

export default api;
