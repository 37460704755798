<template>
  <div class="grid-con">
    <title-con title="监管网格" />
    <map-con @regionchange="costPlannedAmountChange" />
    <div class="grid-list-con">
      <img
        style="margin-left: 32px"
        :src="require('@/assets/images/jgry.png')"
        alt=""
      />
      <h1 class="address">{{ address }}</h1>
      <div class="grid-renyuan">
        <div
          v-for="item in groupList"
          :key="item.userId"
          class="personnel-list"
        >
          <div class="headimg duputi-hov">
            <img
              class="bg"
              :src="require('@/assets/images/headimg.png')"
              alt=""
            />
            <img
              v-if="item.userPic && item.userPic != ''"
              class="userPic"
              :src="item.userPic"
              alt=""
            />
            <img
              v-else
              class="userPic"
              :src="require('@/assets/images/morenimg.png')"
              alt=""
            />
            <div class="sheets-con">
              <div class="personalInfo">
                <img :src="require('@/assets/images/selbg.png')" alt="" />
                <div class="infor-con">
                  <div class="sheets-personnel-list">
                    <div class="headimgtk">
                      <img
                        class="bg"
                        :src="require('@/assets/images/headimg.png')"
                        alt=""
                      />
                      <img
                        v-if="item.userPic && item.userPic != ''"
                        class="userPicinfo"
                        :src="item.userPic"
                        alt=""
                      />
                      <img
                        v-else
                        class="userPicinfo"
                        :src="require('@/assets/images/morenimg.png')"
                        alt=""
                      />
                    </div>
                    <p>{{ item.nickName }}</p>
                    <p>{{ item.supervisePost }}</p>
                  </div>
                  <div class="main-info">
                    <p>职 务：{{ item.supervisePost }}</p>
                    <p>人员分组：组员</p>
                    <p>联系电话：{{ item.phone }}</p>
                    <p>工作单位：{{ item.pointName }}</p>
                    <p>人员职责：{{ item.superviseDuty }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1>{{ item.nickName }}</h1>
          <h1>{{ item.supervisePost }}</h1>
          <h1 class="line"></h1>
          <h1 class="xuxian"></h1>
          <div
            :class="
              item.childList.length > 4
                ? 'deputy-renyuan'
                : 'deputy-renyuan spacearound'
            "
          >
            <div
              v-for="(data, idx) in item.childList"
              :key="data.userId"
              class="deputy-list"
              :class="{ wrap: item.childList.length > 4 }"
            >
              <h1 v-if="idx < 4" class="line"></h1>
              <div class="duputi-hov">
                <div class="headimg">
                  <img
                    class="bg"
                    :src="require('@/assets/images/headimg.png')"
                    alt=""
                  />
                  <img
                    v-if="data.userPic && data.userPic != ''"
                    class="userPic"
                    :src="data.userPic"
                    alt=""
                  />
                  <img
                    v-else
                    class="userPic"
                    :src="require('@/assets/images/morenimg.png')"
                    alt=""
                  />
                </div>
                <div class="sheets-con">
                  <div class="personalInfo">
                    <img :src="require('@/assets/images/selbg.png')" alt="" />
                    <div class="infor-con">
                      <div class="sheets-personnel-list">
                        <div class="headimgtk">
                          <img
                            class="bg"
                            :src="require('@/assets/images/headimg.png')"
                            alt=""
                          />
                          <img
                            v-if="data.userPic && data.userPic != ''"
                            class="userPicinfo"
                            :src="data.userPic"
                            alt=""
                          />
                          <img
                            v-else
                            class="userPicinfo"
                            :src="require('@/assets/images/morenimg.png')"
                            alt=""
                          />
                        </div>
                        <p>{{ data.nickName }}</p>
                        <p>{{ data.supervisePost }}</p>
                      </div>
                      <div class="main-info">
                        <p>职 务：{{ data.supervisePost }}</p>
                        <p>人员分组：组员</p>
                        <p>联系电话：{{ data.phone }}</p>
                        <p>工作单位：{{ data.pointName }}</p>
                        <p>人员职责：{{ data.superviseDuty }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1>{{ data.nickName }}</h1>
              <div
                v-if="data.childList && data.childList.length != 0"
                class="zhuyuan-box"
                @click="zhuyuanHand(data, idx)"
              >
                <span>组员</span>
                <h1 :class="data.check ? 'triangleb' : 'trianglet'"></h1>
              </div>
              <h1 v-if="data.check" class="deputyline"></h1>
              <div
                v-if="data.check"
                :ref="`${data.userId}-popc`"
                class="zhuyuan-data-con"
              >
                <div
                  v-for="deputy in data.childList"
                  :key="deputy.userId"
                  class="deputy-con duputi-hov"
                >
                  <div class="headimg">
                    <img
                      class="bg"
                      :src="require('@/assets/images/headimg.png')"
                      alt=""
                    />
                    <img
                      v-if="deputy.userPic && deputy.userPic != ''"
                      class="userPic"
                      :src="deputy.userPic"
                      alt=""
                    />
                    <img
                      v-else
                      class="userPic"
                      :src="require('@/assets/images/morenimg.png')"
                      alt=""
                    />
                  </div>
                  <h1>{{ deputy.nickName }}</h1>
                  <div class="sheets-con">
                    <div class="personalInfo">
                      <img :src="require('@/assets/images/selbg.png')" alt="" />
                      <div class="infor-con">
                        <div class="sheets-personnel-list">
                          <div class="headimgtk">
                            <img
                              class="bg"
                              :src="require('@/assets/images/headimg.png')"
                              alt=""
                            />
                            <img
                              v-if="deputy.userPic && deputy.userPic != ''"
                              class="userPicinfo"
                              :src="deputy.userPic"
                              alt=""
                            />
                            <img
                              v-else
                              class="userPicinfo"
                              :src="require('@/assets/images/morenimg.png')"
                              alt=""
                            />
                          </div>
                          <p>{{ deputy.nickName }}</p>
                          <p>{{ deputy.supervisePost }}</p>
                        </div>
                        <div class="main-info">
                          <p>职 务：{{ deputy.supervisePost }}</p>
                          <p>人员分组：组员</p>
                          <p>联系电话：{{ deputy.phone }}</p>
                          <p>工作单位：{{ deputy.pointName }}</p>
                          <p>人员职责：{{ deputy.superviseDuty }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
