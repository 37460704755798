import { Progress, Tooltip, message, badge } from "ant-design-vue";
import titleCon from "@/components/title-con/index";
import { mapGetters } from "vuex";
import gridApi from "@/request/api/grid.js";
import mapCon from "@/components/map-con/index";

export default {
  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
    "a-badge": badge,
    titleCon,
    mapCon,
  },
  data() {
    return {
      regionCode: "",
      groupList: [],
      address: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.$eventBus.$emit("changeNav", { index: 2, name: "Grid" });
    this.regionCode = this.userInfo.region;
    this.address = this.userInfo.fullRegionName;
    this.getMeshUser();
  },
  methods: {
    //获取下级展示的弹框的Style，主要是位置
    getdepPopItemStyle(data, idx, item) {
      let mt = "margin-left";
      return {};
    },
    getPcpos(parentElement, childElement) {
      // const parentElement = document.getElementById("parentElementId");
      // const childElement = document.getElementById("childElementId");

      const parentRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();

      const distanceFromParentLeft = childRect.left - parentRect.left;
      const distanceFromParentTop = childRect.top - parentRect.top;
      const distanceFromParentRight = childRect.right - parentRect.right;
      const distanceFromParentBottom = childRect.bottom - parentRect.bottom;
      let result = {
        left: distanceFromParentLeft,
        top: distanceFromParentTop,
        right: distanceFromParentRight,
        bottom: distanceFromParentBottom,
      };
      console.log(result);
      console.log(
        "parentRect.left is ",
        parentRect.left,
        "childRect.left is ",
        childRect.left
      );
      return result;
    },
    async getMeshUser() {
      let params = {
        region: this.regionCode,
      };
      try {
        let res = await gridApi.meshUserTree(params);
        let list = res.data;
        list.forEach((element) => {
          element.childList.forEach((item) => {
            this.$set(item, "check", false);
          });
        });
        this.groupList = list;
      } catch (error) {
        console.log(error);
      }
    },
    costPlannedAmountChange(value) {
      console.log(value);
      this.regionCode = value.region;
      this.address = `${this.userInfo.regionName}>${value.name}`;
      this.getMeshUser();
    },
    async zhuyuanHand(item, idx) {
      if (item.check) {
        item.check = false;
        return;
      }
      this.groupList.forEach((element) => {
        element.childList.forEach((i) => {
          if (i.check) {
            i.check = false;
          }
        });
      });

      item.check = true;
      await this.$nextTick();
      // console.log(this.$refs);
      let { userId } = item;
      let rkey = `${userId}-popc`;
      let popel = this.$refs[rkey][0];
      /*
      let pl = document.getElementsByClassName("personnel-list")[0];
      let resultpos = this.getPcpos(pl, popel);
      let { left, right } = resultpos;
      //判断超过左右边缘
      if (left < 0) {
        const parentRect = pl.getBoundingClientRect();
        const parentCenterX = parentRect.left + parentRect.width / 2;
        let v = Math.abs(parseInt(left));
        popel.style.marginLeft = `${v}px`;
        let findel = this.findParentByClass(popel, "deputy-list");
      }
      if (right > 0) {
      }
      */
      //判断在第几列
      let col = idx % 4;
      let ml = `calc(-${col * 100}% + 40px)`;
      popel.style.marginLeft = ml;
    },
    findParentByClass(element, className) {
      let parent = element.parentNode;
      while (parent) {
        if (parent.classList.contains(className)) {
          return parent;
        }
        parent = parent.parentNode;
      }
      return null;
    },
  },
};
